"use strict";

// Show menu
const hamburger = document.querySelector(".hamburger");
const menu = document.querySelector(".menu");
const menuLine1 = document.querySelector(".line--1");
const menuLine2 = document.querySelector(".line--2");
const menuLine3 = document.querySelector(".line--3");
const headerText = document.querySelectorAll(".slide--text");
const html = document.querySelector(".html");
const body = document.querySelector(".body");
const menuUl = document.querySelector(".menu--ul");
let menuIsOpened = false;

const showMenu = function () {
  hamburger.addEventListener("click", function () {
    if (!menuIsOpened) {
      headerText.forEach((txt) => (txt.style.opacity = 0));
      menuLine1.style.opacity = 0;
      menuLine2.style.transform = "rotate(135deg)";
      menuLine2.style.top = "40px";
      menuLine3.style.transform = "rotate(-135deg)";
      menuLine3.style.top = "40px";
      menu.classList.remove("hidden");
      menuUl.style.paddingBottom = "80px";
      menu.style.transform = "translateY(0)";
      html.style.overflow = "hidden";
      menuIsOpened = true;
      console.log("menuIsOpened");
    } else {
      headerText.forEach((txt) => (txt.style.opacity = 1));
      menuLine1.style.opacity = 1;
      menuLine2.style.transform = "rotate(180deg)";
      menuLine2.style.top = "30px";
      menuLine3.style.transform = "rotate(-180deg)";
      menuLine3.style.top = "50px";
      menu.classList.add("hidden");
      menuUl.style.paddingBottom = "0";
      menu.style.transform = "translateY(-150%)";
      html.style.overflow = "auto";
      menuIsOpened = false;
      // Chiusura automatica sottomenu
      if (submenuIsOpen == true) {
        ulSquadre.style.display = "none";
        arrowDown.classList.remove("display--none");
        arrowUp.classList.add("display--none");
        submenuIsOpen = false;
      }
    }
  });
};

showMenu();

// Show submenu squadre
const squadre = document.querySelector(".squadre");
const ulSquadre = document.querySelector(".ul--squadre");
const arrowDown = document.querySelector(".arrow--down");
const arrowUp = document.querySelector(".arrow--up");
let submenuIsOpen = false;

const showSubmenu = function () {
  squadre.addEventListener("click", function () {
    if (submenuIsOpen == false) {
      ulSquadre.style.paddingTop = "15px";
      ulSquadre.style.paddingBottom = "15px";
      ulSquadre.style.maxHeight = "1000px";
      ulSquadre.style.overflow = "visible";
      arrowDown.classList.add("display--none");
      arrowUp.classList.remove("display--none");
      submenuIsOpen = true;
    } else {
      ulSquadre.style.paddingTop = "unset";
      ulSquadre.style.paddingBottom = "unset";
      ulSquadre.style.maxHeight = "0";
      ulSquadre.style.overflow = "hidden";
      arrowDown.classList.remove("display--none");
      arrowUp.classList.add("display--none");
      submenuIsOpen = false;
    }
  });
};
showSubmenu();

// Sticky menu background

const nav = document.querySelector("#navbar");
let lastScrollY = window.scrollY;
window.addEventListener("scroll", function () {
  if (this.window.scrollY > 400) {
    nav.classList.add('scrolled-nav');
  } else {
    nav.classList.remove('scrolled-nav');
  }
});
